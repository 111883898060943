import logoProvider from '@src/static/images/icon/logo-transparent.png';
import { getCustomer } from '@src/utils/customer';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { connect } from 'react-redux';

import PlacesSearch from '../PlacesSearch';
import SmartSearch from './smart-search';

const Header = props => {
	const router = useRouter();

	const [menuOpen, setMenuOpen] = useState(false);

	const openMenu = () => setMenuOpen(true);
	const isMenuOpen = state => {
		setMenuOpen(state.isOpen);
	};

	const { cartlist, isSimplified } = props;
	const isAuthenticated = !!getCustomer()?.customer;

	const logoDefault = props.masterProvider.isActive ? props.masterProvider.imageUrl : logoProvider;

	const phoneNumber = props.masterProvider.isActive ? props.masterProvider.phoneNumber : '1-800-228-8379';
	let linkAs = '';
	let linkHref = '';

	linkAs =
		router.query.category === 'vehicle-batteries-hybrid-electric'
			? '/category/vehicle-batteries-hybrid-electric'
			: '/category/vehicle-batteries-car-or-truck';
	linkHref =
		router.query.category === 'vehicle-batteries-hybrid-electric'
			? '/category/[categoryid]?categoryid=vehicle-batteries-hybrid-electric'
			: '/category/[categoryid]?categoryid=vehicle-batteries-car-or-truck';

	if (isSimplified) {
		return (
			<div className="header-simplified">
				<div className="container">
					<div className="inner">
						<div className="logo-container">
							<Link href={`/`}>
								<img src={logoDefault?.src || logoDefault} className="" alt="Logo" />
							</Link>
						</div>

						<div className="menu-items-container">
							<PlacesSearch addressSearch modalOnly />
							<ul>
								<li>
									<Link href="#services">Our Services</Link>
								</li>
								<li>
									<Link href="#how-it-works">How it Works</Link>
								</li>
								<li className="highlighted">
									<Link href={linkHref} as={linkAs}>
										Get Started
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div>
			<div className="burger-menu-container">
				<Menu width="250px" isOpen={menuOpen} onStateChange={isMenuOpen}>
					<div className="burger-logo">
						<Link href={`/`} as={`/`}>
							<a className="menu-item">
								<Image objectFit="contain" width="150px" height="64px" src={logoDefault} alt="logo" />
							</a>
						</Link>
					</div>
					<ul className="burger-navigation-items user-related">
						<li>
							<Link href={`/login`} as={`/login`}>
								<a className="menu-item">
									<i className="fas fa-user"></i> Account
								</a>
							</Link>
						</li>
					</ul>

					<ul className="burger-navigation-items">
						<li>
							{isAuthenticated && (
								<ul className="dropdown">
									<li>
										<Link href={`/dashboard`} as={`/dashboard`}>
											<a className="menu-item">
												<i className="fad fa-user-cog"></i> Dashboard
											</a>
										</Link>
									</li>

									<li className="logout">
										<Link href={`/logout`} as={`/logout`}>
											<a className="menu-item">
												<i className="fad fa-sign-out-alt"></i> Logout
											</a>
										</Link>
									</li>
								</ul>
							)}

							{!isAuthenticated && (
								<ul className="dropdown">
									<li>
										<Link href={`/login`} as={`/login`}>
											<a className="menu-item">
												<i className="fad fa-sign-in-alt"></i> Login
											</a>
										</Link>
									</li>

									<li>
										<Link href={`/register`} as={`/register`}>
											<a className="menu-item">
												<i className="fas fa-user-plus"></i> Register
											</a>
										</Link>
									</li>
								</ul>
							)}
						</li>

						<hr />

						<li>
							<Link href={`/categories`} as={`/categories`}>
								<a className="menu-item">
									<i className="fas fa-bars"></i> All Categories
								</a>
							</Link>
						</li>
					</ul>
				</Menu>
			</div>

			<div className="header-new" id="header-new">
				<div className="container">
					<div className="row align-items-center">
						<div className="col logo-col">
							<div className="logo-container">
								<div className="with-menu-icon">
									<button aria-label="All Categories" onClick={() => openMenu()}>
										<i className="fas fa-bars"></i>
									</button>

									<Link href={`/`}>
										<a className="menu-item">
											<Image
												width="200px"
												height="58px"
												src={logoDefault}
												alt="1800 Battery Logo"
											/>
										</a>
									</Link>
								</div>

								<div className="cart">
									<Link href={`/cart`} as={`/cart`}>
										<a>
											<i className="fas fa-shopping-cart"></i>{' '}
											<span>({cartlist.filter(item => item.price).length})</span>
										</a>
									</Link>
								</div>
							</div>
						</div>

						<div className="col">
							<div className="contenful">
								<div className="smart-search-container">
									<SmartSearch />
								</div>

								<nav className="navigation desktop user-related-container">
									<ul className="site-related">
										<li>
											<Link href={`/categories`} as={`/categories`}>
												<a className="menu-item">
													<i className="fas fa-bars"></i> All Categories
												</a>
											</Link>
										</li>
									</ul>

									<ul className="user-related temp">
										<li>
											<Link href={`/locations`} as={`/locations`}>
												<a>
													<i className="fas fa-map-marked-alt"></i> Locations
												</a>
											</Link>
										</li>
										<li>
											<Link
												href={`${isAuthenticated ? '/dashboard' : '/login'}`}
												as={`${isAuthenticated ? '/dashboard' : '/login'}`}>
												<a>
													<i className="fas fa-user"></i> Account
												</a>
											</Link>

											{isAuthenticated && (
												<ul className="dropdown">
													<li>
														<Link href={`/dashboard`} as={`/dashboard`}>
															<a>
																<i className="fad fa-user-cog"></i> Dashboard
															</a>
														</Link>
													</li>

													<li className="logout">
														<Link href={`/logout`} as={`/logout`}>
															<a>
																<i className="fad fa-sign-out-alt"></i> Logout
															</a>
														</Link>
													</li>
												</ul>
											)}

											{!isAuthenticated && (
												<ul className="dropdown">
													<li>
														<Link href={`/login`} as={`/login`}>
															<a>
																<i className="fad fa-sign-in-alt"></i> Login
															</a>
														</Link>
													</li>

													<li>
														<Link href={`/register`} as={`/register`}>
															<a>
																<i className="fas fa-user-plus"></i> Register
															</a>
														</Link>
													</li>
												</ul>
											)}
										</li>

										<li className="cart">
											<Link href={`/cart`} as={`/cart`}>
												<a>
													<i className="fas fa-shopping-cart"></i>{' '}
													<span>({cartlist.filter(item => item.price).length})</span>
												</a>
											</Link>
										</li>
									</ul>
								</nav>
							</div>
						</div>

						<div className="col far-right">
							<div className="need-help-new">
								<p>
									Need help? Call us at{' '}
									<span>
										<a style={{ color: 'inherit' }} href={`tel:${phoneNumber.split('-').join('')}`}>
											{phoneNumber}
										</a>
									</span>
								</p>
							</div>
							<nav className="navigation">
								<ul className="user-related">
									<li>
										<Link href={`/locations`} as={`/locations`}>
											<a>
												<i className="fas fa-map-marked-alt"></i> Locations
											</a>
										</Link>
									</li>
									<li>
										<Link
											href={`${isAuthenticated ? '/dashboard' : '/login'}`}
											as={`${isAuthenticated ? '/dashboard' : '/login'}`}>
											<a>
												<i className="fas fa-user"></i> Account
											</a>
										</Link>

										{isAuthenticated && (
											<ul className="dropdown">
												<li>
													<Link href={`/dashboard`} as={`/dashboard`}>
														<a>
															<i className="fad fa-user-cog"></i> Dashboard
														</a>
													</Link>
												</li>

												<li className="logout">
													<Link href={`/logout`} as={`/logout`}>
														<a>
															<i className="fad fa-sign-out-alt"></i> Logout
														</a>
													</Link>
												</li>
											</ul>
										)}

										{!isAuthenticated && (
											<ul className="dropdown">
												<li>
													<Link href={`/login`} as={`/login`}>
														<a>
															<i className="fad fa-sign-in-alt"></i> Login
														</a>
													</Link>
												</li>

												<li>
													<Link href={`/register`} as={`/register`}>
														<a>
															<i className="fas fa-user-plus"></i> Register
														</a>
													</Link>
												</li>
											</ul>
										)}
									</li>

									<li className="cart">
										<Link href={`/cart`} as={`/cart`}>
											<a>
												<i className="fas fa-shopping-cart"></i>{' '}
												<span>({cartlist.filter(item => item.price).length})</span>
											</a>
										</Link>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	cartlist: state.cartlist.cart,
});

export default connect(mapStateToProps)(Header);
